import React from "react";
import { Link, graphql } from "gatsby";
import FormLayout from "../components/FormLayout";
import parse from "html-react-parser";

const ThankYou = ({ data }) => {
  const content = data?.wpPage?.pageContent?.pageContent?.[0];
  if (!content) {
    return (
      <FormLayout >
        <div className="thankyou-content container mx-auto w-full flex flex-col items-center justify-center text-white text-center max-w-[450px] m-auto">
          <h2>Thank you for your enquiry</h2>
          <p className="text-center my-20">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.
          </p>
          <Link to="/" className="button btn-transparent cursor-pointer">GO TO HOME</Link>
        </div>
      </FormLayout>
    )
  }
  return (
    <FormLayout isLoading={false} sideImage={content?.sideImage}>
        <div className="thankyou-content container mx-auto w-full flex flex-col items-center justify-center text-white text-center max-w-[450px] m-auto">
          {content?.heading && <h2>{content?.heading}</h2>}
          <div className="text-center my-20">
            {content?.description && parse(content?.description)}
          </div>
          {content?.link ? <Link to={content.link?.url} className="button btn-transparent cursor-pointer">{content.link?.title}</Link> : <Link to="/" className="button btn-transparent cursor-pointer">GO TO HOME</Link>}
        </div>
      </FormLayout>
  )
}

export default ThankYou;
export const ThankYouPageContentQuery = graphql`
  query thankYouPageContent {
    wpPage(slug: {eq: "thank-you"}) {
      pageContent {
        pageContent {
          __typename
          ... on WpPage_Pagecontent_PageContent_ThankYouContent {
            description
            fieldGroupName
            heading
            link {
              target
              title
              url
            }
            sideImage {
              altText
              gatsbyImage(formats: WEBP, placeholder: BLURRED, width: 2000, quality: 90)
            }
          }
        }
      }
    }
  }
`;